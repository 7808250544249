<template>
  <div class="tw-payment-form-element w-full flex-col overflow-hidden">
    <FormError
      v-if="pageData.paymentFormError"
      :is-editor-mode="isEditorMode"
      :page-data="pageData"
      :page-options="pageOptions"
      :locale="locale"
    />
    <div
      v-if="!pageData.paymentFormError"
      class="p-8 md:p-24"
      :class="{'flex flex-1': !hasPaymentMethod || pageData.isSuccessLoading}"
    >
      <component
        :is="isEditorMode ? 'div' : WebForm"
        ref="paymentFormElement"
        @before-submit="() => { pageData.paymentMethod = pageData.providerStatus[STRIPE] ? STRIPE : CREDIT_CARD }"
        @submit="clickProvider(pageData.providerStatus[STRIPE] ? STRIPE : CREDIT_CARD)"
      >
        <WebSpinner v-if="pageData.isSuccessLoading" class="flex min-h-[530px] flex-col items-center justify-center" />
        <template v-else-if="hasPaymentMethod">
          <PaymentStripeInputs
            v-if="getProviderStatus(STRIPE)"
            ref="stripeForm"
            v-model="form"
            :element="element"
            :locale="locale"
            :page-data="pageData"
            :page-options="pageOptions"
            :consent-style="consentStyle"
            :is-editor-mode="isEditorMode"
            :stripe-token="pageData.stripeToken"
          />
          <PaymentFormInputs
            v-else-if="getProviderStatus(CREDIT_CARD)"
            v-model="form"
            :page-options="pageOptions"
            :is-label-visible="isLabelVisible"
            :label-style="labelStyle"
            :consent-style="consentStyle"
            :locale="locale"
            :is-editor-mode="isEditorMode"
            :page-data="pageData"
            :element="element"
            :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
          />

          <template v-if="!getProviderStatus(STRIPE) && !getProviderStatus(CREDIT_CARD)">
            <RegisterInput
              v-if="pageData.registerOnPayment"
              v-model:subscriber-id="form.email"
              hide-full-name-input
              :disabled="pageData.bypassRegister"
              class="mt-16"
              :locale="locale"
              :is-editor-mode="isEditorMode"
              :element="element"
              :page-data="pageData"
              :page-options="pageOptions"
              @error="handleInputError"
            />
            <AcceptPolicy
              v-if="isEditorMode || pageData.isPolicyRequired"
              v-model="form.acceptPolicy"
              :page-options="pageOptions"
              :page-data="pageData"
              :locale="locale"
              :consent-style="consentStyle"
              :input-class="{ 'pointer-events-none' : isEditorMode }"
              :is-editor-mode="isEditorMode"
              :disabled="isSubmitLoading || isLoadingPackage || isSubmitDisabled"
              class="mb-32 mt-16"
            />
          </template>

          <PriceInfo
            class="mb-24 mt-32 lg:mb-32"
            :page-options="pageOptions"
            :label-style="labelStyle"
            :total-price="totalPrice"
            :locale="locale"
            :is-editor-mode="isEditorMode"
            :class="{ '!mt-8 md:!mt-0': !getProviderStatus(STRIPE) && !getProviderStatus(CREDIT_CARD) }"
          />

          <!-- DEFAULT SLOT (SUBMIT BUTTON) -->
          <template v-if="isEditorMode && (getProviderStatus(STRIPE) || getProviderStatus(CREDIT_CARD))">
            <div v-if="$slots.default" class="tw-payment-form-element__button">
              <slot />
            </div>
          </template>
          <div v-else-if="slotDefault.length && (getProviderStatus(STRIPE) || getProviderStatus(CREDIT_CARD))" class="tw-payment-form-element__button">
            <template v-for="subEl of slotDefault" :key="subEl.id">
              <ElementWrapper
                v-if="subEl.options.visible"
                :el="subEl"
                :page-data="submitButtonProps"
                :page-options="pageOptions"
                :locale="locale"
              />
            </template>
          </div>

          <PaymentMethodIcons v-if="getProviderStatus(STRIPE) || getProviderStatus(CREDIT_CARD)" class="mb-16 mt-8" />

          <div
            v-if="isOtherPaymentMethodsActive"
            class="flex flex-col gap-16 md:gap-24"
            :class="{ 'pointer-events-none': isEditorMode }"
          >
            <Hr v-if="getProviderStatus(STRIPE) || getProviderStatus(CREDIT_CARD)">
              {{ translate('generate.common.or', locale) }}
            </Hr>

            <div v-if="getProviderStatus(PAYPAL)">
              <ProviderButton
                :provider="PAYPAL"
                :loading="$wait.is(`${PAYPAL}:paymentProcessing`)"
                :disabled="isSubmitLoading"
                class="!bg-[#F6C444]"
                @[btnClick]="clickProvider(PAYPAL)"
              />
              <div class="mt-4 text-center text-12 font-semibold">
                {{ translate('generate.pages.payment.paypalMotto', locale) }}
              </div>
            </div>

            <div v-if="getProviderStatus(APPLE_PAY) || getProviderStatus(GOOGLE_PAY)" class="flex gap-16">
              <template v-for="provider in [APPLE_PAY, GOOGLE_PAY]" :key="provider">
                <ProviderButton
                  v-if="getProviderStatus(provider)"
                  :provider="provider"
                  :loading="$wait.is([`${provider}:paymentProcessing`])"
                  :disabled="isSubmitLoading"
                  :dark-mode="!!element.options?.darkModeOnTabButtons"
                  @[btnClick]="clickProvider(provider)"
                />
              </template>
            </div>
          </div>

          <!-- INFO TEXT -->
          <template v-if="isEditorMode">
            <div v-if="$slots.infoText" class="tw-payment-form-element__info-text mt-24">
              <slot name="infoText"></slot>
            </div>
          </template>
          <div v-else-if="slotInfoText.length" class="tw-payment-form-element__info-text mt-24">
            <template v-for="subEl of slotInfoText" :key="subEl.id">
              <ElementWrapper
                v-if="subEl.options.visible"
                :el="subEl"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </template>
          </div>

          <!-- INFO TEXT 2 -->
          <template v-if="isEditorMode">
            <div v-if="$slots.infoText2" class="tw-payment-form-element__info-text">
              <slot name="infoText2"></slot>
            </div>
          </template>
          <div v-else-if="slotInfoText2.length" class="tw-payment-form-element__info-text">
            <template v-for="subEl of slotInfoText2" :key="subEl.id">
              <ElementWrapper
                v-if="subEl.options.visible"
                :el="subEl"
                :locale="locale"
                :page-data="pageData"
                :page-options="pageOptions"
              />
            </template>
          </div>
        </template>
        <template v-else>
          <NoPaymentMethodInfo
            :is-editor-mode="isEditorMode"
            :locale="locale"
            :page-options="pageOptions"
          />
        </template>
      </component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, type PropType, toRefs } from 'vue';
import {
  PaymentMethodIcons,
  PaymentFormInputs,
  PaymentStripeInputs,
  ProviderButton,
  PriceInfo,
  NoPaymentMethodInfo,
  Hr
} from '@shared/elements/common/payment/components';
import { PaymentProvider, type PaymentPageData } from '@shared/elements/common/payment/types';
import { WebForm, WebSpinner } from '@shared/components';
import { getElementsBySlot } from '@shared/utils';
import { $wait } from '@shared/utils/wait';
import type { Element as ElementType, PageOptions } from '@shared/types/model';
import type { ElementOptions } from '@shared/types/options';
import { useTranslate } from '@shared/composable/useTranslate';
import ElementWrapper from '../wrapper.vue';
import { usePaymentElement } from '@shared/elements/common/payment/composables/usePaymentElement';
import FormError from '@shared/elements/common/payment/components/form-error/index.vue';
import AcceptPolicy from '@shared/elements/common/payment/components/accept-policy/index.vue';
import RegisterInput from '@shared/elements/common/registration/components/registerInput.vue';
import { globalEmit } from '@shared/utils/helpers';

const props = defineProps({
  isEditorMode: { type: Boolean, default: true },
  config: { type: Object as PropType<ElementOptions['payment']>, default: () => ({}) },
  pageData: { type: Object as PropType<PaymentPageData>, default: () => ({}) },
  pageOptions: { type: Object as PropType<PageOptions>, default: () => ({}) },
  element: { type: Object as PropType<ElementType<'payment'>>, default: () => ({}) },
  locale: { type: String, default: '' }
});

const { translate } = useTranslate();

const { CREDIT_CARD, PAYPAL, GOOGLE_PAY, APPLE_PAY, STRIPE } = PaymentProvider;

const { element, pageOptions, pageData } = toRefs(props);
const {
  form,
  stripeForm,
  paymentFormElement,
  clickProvider,
  labelStyle,
  consentStyle,
  isLabelVisible,
  getProviderStatus,
  isOtherPaymentMethodsActive,
  totalPrice,
  isSubmitLoading,
  isSubmitDisabled,
  isLoadingPackage,
  hasPaymentMethod
} = usePaymentElement({ element, pageData, pageOptions });

const btnClick = computed(() => (props.isEditorMode ? '' : 'click'));

const submitButtonProps = computed(() => ({
  isLoading: $wait.is(`${CREDIT_CARD}:paymentProcessing`),
  isDisabled: isSubmitDisabled.value,
  applyConditionalValue: props.pageData?.applyConditionalValue,
  getActiveCondition: props.pageData?.getActiveCondition
}));

const slotDefault = computed(() => {
  return getElementsBySlot(props.element.elements);
});
const slotInfoText = computed(() => {
  return getElementsBySlot(props.element.elements, 'infoText');
});
const slotInfoText2 = computed(() => {
  return getElementsBySlot(props.element.elements, 'infoText2');
});
function handleInputError(errMessage: string) {
  globalEmit('paymentFormInputError', errMessage);
}
</script>

<style lang="postcss" scoped>
.tw-payment-form-element {
  &__info-text {
    a {
      color: #007aff !important;
    }
  }
}

@media (max-width: 640px) {
  .tw-payment-form-element {
    @apply border-none w-full;
  }
}

/* For editor screen only */
.tw-editor__screen {
  &.mobile {
    .tw-payment-form-element {
      @apply border-none w-full;
    }
  }
}
</style>
